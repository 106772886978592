import React from "react";

export function MortgageForm({
                               housePrice,
                               interestRate,
                               loanTerm,
                               onHousePriceChange,
                               onInterestRateChange,
                               onLoanTermChange
                             }) {
  return (
    <form className="calculator mortgage-form">
      <label>
        House Price:
        <input
          type="number"
          name="housePrice"
          value={housePrice}
          onChange={onHousePriceChange}
          placeholder="e.g. 600000"
        />
      </label>
      <label>
        Interest Rate (%):
        <input
          type="number"
          name="interestRate"
          value={interestRate}
          onChange={onInterestRateChange}
          placeholder="e.g. 6.55"
        />
      </label>
      <label>
        Loan Term (years):
        <input
          type="number"
          name="loanTerm"
          value={loanTerm}
          onChange={onLoanTermChange}
          placeholder="e.g. 10"
        />
      </label>
    </form>
  );
}
