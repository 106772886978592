import React, { useMemo, useState } from 'react';
import { calculateAlternatePayments, calculateMortgage } from './mortgageCalculator';
import ResultTable from './ResultTable';
import Summary from './Summary';
import SummaryContent from './SummaryContent';
import houseCalculator from './img/house-calculator.png';
import './App.css';
import { MortgageForm } from "./MortgageForm";

function App() {
  const [housePrice, setHousePrice] = useState('');
  const [interestRate, setInterestRate] = useState('');
  const [loanTerm, setLoanTerm] = useState('');

  const handleHousePriceChange = (event) => {
    setHousePrice(event.target.value);
  };

  const handleInterestRateChange = (event) => {
    setInterestRate(event.target.value);
  };

  const handleLoanTermChange = (event) => {
    setLoanTerm(event.target.value);
  };

  const { monthlyPayment, totalInterest, totalPrincipal, tableRows } = useMemo(() => {
    if (housePrice && interestRate && loanTerm) {
      return calculateMortgage(housePrice, interestRate, loanTerm);
    }
    return {};
  }, [housePrice, interestRate, loanTerm]);

  const totalAmountSpent = useMemo(() => Number(housePrice) + totalInterest, [housePrice, totalInterest]);

  const weeklyPayment = useMemo(() => monthlyPayment * 12 / 52, [monthlyPayment]);
  const yearlyPayment = useMemo(() => monthlyPayment * 12, [monthlyPayment]);

  const { savings: weeklySavings } = useMemo(() => calculateAlternatePayments(housePrice, interestRate, loanTerm, 1), [housePrice, interestRate, loanTerm]);
  const { savings: fortnightlySavings } = useMemo(() => calculateAlternatePayments(housePrice, interestRate, loanTerm, 2), [housePrice, interestRate, loanTerm]);

  return (
    <>
      <h1>
        <img src={houseCalculator} className="logo" alt="HouseCalculator.co.nz Logo" /> House Price Calculator
      </h1>
      <p>How much does a house really cost?</p>
      <MortgageForm
        housePrice={housePrice}
        interestRate={interestRate}
        loanTerm={loanTerm}
        onHousePriceChange={handleHousePriceChange}
        onInterestRateChange={handleInterestRateChange}
        onLoanTermChange={handleLoanTermChange}
      />
      <Summary>
        <SummaryContent
          totalPrincipal={totalPrincipal}
          totalInterest={totalInterest}
          totalAmountSpent={totalAmountSpent}
          weeklyPayment={weeklyPayment}
          monthlyPayment={monthlyPayment}
          yearlyPayment={yearlyPayment}
          fortnightlySavings={fortnightlySavings}
          weeklySavings={weeklySavings}
        />
      </Summary>
      { !isNaN(totalAmountSpent) ? <ResultTable resultTable={tableRows} /> : null }
    </>
  );
}

export default App;
