import React from 'react';

function Summary({ children }) {
  return <div id="summary">
    <h3>Summary</h3>
    <p className="note">
      Prices assume <strong>monthly</strong> mortgage payments.
    </p>
    {children}
  </div>;
}

export default Summary;
