const MONTHS_IN_YEAR = 12;
const WEEKS_IN_YEAR = 52;

function formatNumber(number) {
  return Math.round(number).toLocaleString();
}

export function calculateMortgage(housePrice, interestRate, loanTerm) {
  const housePriceNum = Number(housePrice);
  const interestRateNum = Number(interestRate) / 100;
  const loanTermYears = Number(loanTerm);

  const monthlyInterestRate = interestRateNum / MONTHS_IN_YEAR;
  const numberOfPayments = loanTermYears * MONTHS_IN_YEAR;
  const monthlyPayment =
    (housePriceNum * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
    (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);

  let remainingBalance = housePriceNum;
  let totalInterest = 0;
  let totalPrincipal = 0;

  let tableRows = '';

  for (let year = 1; year <= loanTermYears; year++) {
    let yearlyInterest = 0;
    let yearlyPrincipal = 0;

    for (let month = 1; month <= MONTHS_IN_YEAR; month++) {
      const interestPaid = remainingBalance * monthlyInterestRate;
      const principalPaid = monthlyPayment - interestPaid;
      remainingBalance -= principalPaid;

      yearlyInterest += interestPaid;
      yearlyPrincipal += principalPaid;
    }

    totalInterest += yearlyInterest;
    totalPrincipal += yearlyPrincipal;

    tableRows += `
      <tr>
        <td>${year}</td>
        <td>$${formatNumber(yearlyInterest)}</td>
        <td>$${formatNumber(yearlyPrincipal)}</td>
        <td>$${formatNumber(remainingBalance)}</td>
      </tr>
    `;
  }

  tableRows += `
    <tr>
      <th>Total</th>
      <th>$${formatNumber(totalInterest)}</th>
      <th>$${formatNumber(totalPrincipal)}</th>
      <th>-</th>
    </tr>
  `;

  return {
    monthlyPayment,
    totalInterest,
    totalPrincipal,
    remainingBalance,
    tableRows,
  };
}

export function calculateAlternatePayments(housePrice, interestRate, loanTerm, paymentFrequency) {
  const housePriceNum = Number(housePrice);
  const interestRateNum = Number(interestRate) / 100;
  const loanTermYears = Number(loanTerm);

  const paymentsPerYear = WEEKS_IN_YEAR / paymentFrequency;
  const alternateInterestRate = interestRateNum / paymentsPerYear;
  const numberOfPayments = loanTermYears * paymentsPerYear;
  const alternatePayment =
    (housePriceNum * alternateInterestRate * Math.pow(1 + alternateInterestRate, numberOfPayments)) /
    (Math.pow(1 + alternateInterestRate, numberOfPayments) - 1);

  let remainingBalance = housePriceNum;
  let totalAlternateInterest = 0;

  for (let year = 1; year <= loanTermYears; year++) {
    let yearlyAlternateInterest = 0;

    for (let payment = 1; payment <= paymentsPerYear; payment++) {
      const interestPaid = remainingBalance * alternateInterestRate;
      const principalPaid = alternatePayment - interestPaid;
      remainingBalance -= principalPaid;

      yearlyAlternateInterest += interestPaid;
    }

    totalAlternateInterest += yearlyAlternateInterest;
  }

  return {
    payment: alternatePayment,
    totalInterest: totalAlternateInterest,
    savings: calculateMortgage(housePrice, interestRate, loanTerm).totalInterest - totalAlternateInterest,
  };
}
