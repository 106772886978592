import React from 'react';

function SummaryContent({
                          totalPrincipal,
                          totalInterest,
                          totalAmountSpent,
                          weeklyPayment,
                          monthlyPayment,
                          yearlyPayment,
                          fortnightlySavings,
                          weeklySavings
                        }) {
  const displayNumber = (val) => isNaN(val) ? "" : Math.round(Number(val)).toLocaleString();

  return (
    <>
      <table>
        <tbody>
        <tr>
          <td>House Cost:</td>
          <td>
            <strong>${displayNumber(totalPrincipal)}</strong>
          </td>
        </tr>
        <tr>
          <td>Interest:</td>
          <td>
            <strong>${displayNumber(totalInterest)}</strong>
          </td>
        </tr>
        <tr>
          <td>
            <strong>Total Spent:</strong>
          </td>
          <td>
            <strong>${displayNumber(totalAmountSpent)}</strong>
          </td>
        </tr>
        </tbody>
      </table>
      <h4>Budgeting:</h4>
      <table>
        <thead>
        <tr>
          <th>Cost per</th>
          <th>Amount</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>week</td>
          <td>
            <strong>${displayNumber(weeklyPayment)}</strong>
          </td>
        </tr>
        <tr>
          <td>month</td>
          <td>
            <strong>${displayNumber(monthlyPayment)}</strong>
          </td>
        </tr>
        <tr>
          <td>year</td>
          <td>
            <strong>${displayNumber(yearlyPayment)}</strong>
          </td>
        </tr>
        </tbody>
      </table>
      <p className="tip">
        Tip: You can potentially save <strong>~${displayNumber(fortnightlySavings)}</strong> by paying fortnightly, or
        <strong>~${displayNumber(weeklySavings)}</strong> total by making weekly payments instead of monthly.
      </p>
    </>
  );
}

export default SummaryContent;
