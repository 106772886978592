import React from 'react';

function ResultTable({ resultTable }) {
  return (
    <div id="yearly_breakdown">
      <h3>Payments per year:</h3>
      <table>
        <thead>
        <tr>
          <th>Year</th>
          <th>Interest Paid</th>
          <th>Principal Paid</th>
          <th>Remaining Balance</th>
        </tr>
        </thead>
        <tbody dangerouslySetInnerHTML={{ __html: resultTable }}></tbody>
      </table>
    </div>
  );
}

export default ResultTable;
